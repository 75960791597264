'use client';

import type {
  Admin,
  FeatureFlags,
} from '@medsimples/doctor-onboarding-openapi-v2';
import { type ReactNode, createContext, useContext } from 'react';

export interface AuthUser {
  name: string;
  email: string;
  isAuthenticated: boolean;
}

export interface AuthProviderValue {
  user: AuthUser;
  logoutURL?: string;
  permissions: Record<string, string[]>;
  featureFlags: FeatureFlags;
}

const AuthContext = createContext({} as AuthProviderValue);

const AuthProviderClient = ({
  authContext,
  adminUser,
  children,
}: {
  authContext?: {
    isAuthenticated: boolean;
    name: string;
    logoutURL?: string;
  };
  adminUser?: Admin;
  children: ReactNode;
}) => {
  return (
    <AuthContext.Provider
      value={{
        user: {
          email: adminUser?.email,
          isAuthenticated: authContext?.isAuthenticated,
          name: authContext?.name,
        },
        logoutURL: authContext?.logoutURL,
        featureFlags: adminUser?.settings?.featureFlags,
        permissions: adminUser?.permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProviderClient;

export const useAuth = () => {
  return useContext(AuthContext);
};
